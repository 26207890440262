
import { Bitrix24, BxButton, BxButtonWrapper } from 'vue-bitrix24';
import 'vue-bitrix24/css';

const useBitrix24 = {
    install(app) {
      [BxButtonWrapper, BxButton].forEach((Component) => {
        app.component(Component.name, Component);
      });
    },
  };

export { Bitrix24, useBitrix24 };