<template>
    <div class="wrapper">
        <table class="table table-striped">
                <thead>
                    <tr v-if="dataTable[0]">
                    <th v-for="(index, key) in dataTable[0]" :key="index">{{key}}</th>
                    </tr>
                    <tr>
                        <th><router-link :to="{name: 'workgroup-add'}">Add</router-link></th>
                    </tr>
                </thead>
                <tbody>
                    <tr @click="getKey($event)" v-for="(element) in dataTable" :key="element.ID" :id="element.ID">
                        <th v-for="(attributo,key) in element" :key="key">{{attributo}}</th>
                        <th></th>
                    </tr>
                </tbody>
            </table>
            <div v-if="axiosErrors" class="alert alert-primary" role="alert">
                {{axiosErrors}}
            </div>

            
    </div>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue';


export default defineComponent({ 
    name: 'WorkgroupTableComponent',
    components: {},
    props:{
       
    },

    data(){
      return{
        be_url: process.env.VUE_APP_BE_URL,
        loading: false,
        post: null,
        axiosErrors: null,
        data:[]
      }
    },

    computed: {
        dataTable(){
           return this.data;
        }
    },

    created() {
        //this.fetchData();
    },

    mounted: function () {
        console.log("MOUNTED");
       this.fetchData();
    },

    watch: {
        // call again the method if the route changes
        '$route': 'fetchData',
        data(newData, oldData){
            if(newData != oldData)
                this.axiosErrors = null
        }
    },

    methods: {
       async fetchData(){
            this.error = this.post = null
            this.loading = true
            await axios.get(this.be_url + '/api/workgroup')
                .then(res=>{
                    this.post = true;
                    this.data = res.data;
                    console.log("RESPONSE");
                    console.log(res);
                })
                .catch(err=>{
                    this.axiosErrors = err.response.errors;
                    console.log("ERROR");
                    console.log(err.response);
                })
                .finally(()=>{
                    this.loading = false;
                })
       },
    },
});

</script>