
import router from "@/router";
import VueJwtDecode from 'vue-jwt-decode'

export default {
    namespaced: true,
    state: {
        jwt: null,
        test: "ciao sono test store",
        user: {},
        user_roles: [],
        previousPath: '',
    },

    getters: {
        getUser: state => state.user,
        jwt: state => state.jwt,
        authenticated: state => state.jwt !== null,
        getUserRoles: state => state.user_roles,
        getPreviousPath: (state) => state.previousPath,
        isAppUser: (state) => state.user.b24User_id == null || state.user.b24User_id == undefined
    },

    mutations: {
        SET_JWT(state, jwt) {
            state.jwt = jwt;
        },
        SET_USER(state, user){
            state.user = user;
        },
        SET_USER_ROLES(state, roles){
            if(typeof roles == "string")
                state.user_roles = [roles];
            else if (typeof roles == "object"){
                state.user_roles = roles;
            }
            console.log(roles);
        },
        setPreviousPath(state, path) {
          state.previousPath = path;
        },
    },

    actions: {
        async validateRequest({ commit }, jwtToken) {
            if (jwtToken) {
                const user = VueJwtDecode.decode(jwtToken);
                commit('SET_JWT', jwtToken);
                if(user.role != null)
                    commit("SET_USER_ROLES", user.role)
                commit
                // delete cookie. 
                // Essendo l'applicazione hostata in un IFrame, possiamo cancellarlo.
                // Sar� reinizializzato ogni volta che l'applicazione sar� refreshata lato bitrix
                //cookies.remove("FILTER_APP");
            }else{
                router.push({name: "Page401"});
            }
        },
        logOut({commit, getters}){
            commit("SET_JWT", null)
            commit("SET_USER", {})
            commit("SET_USER_ROLES", [])
            let url = getters.getPreviousPath;
            console.log(url);
            router.push({name:"login", query: {returnUrl: url}});
        },
        
        updatePreviousPath({ commit }, path) {
            commit('setPreviousPath', path);
        },
    },
    
}