import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes';
import store from '@/store';
import UseAuthenticationService from '@/services/composition/AuthenticationService';

const {CreateAxiosInstance} = UseAuthenticationService();

const router = new createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,  
    scrollBehavior() {
      return { top: 0 }
    },
})

// Utilizza una promise per attendere che lo store sia pronto
const waitForStoreToBeReady = async () => {
   while (!store || !store.getters) {
    await new Promise(resolve => setTimeout(resolve,100))
   }
};

router.beforeResolve(async (to, from, next) => {
    next()
})
router.beforeEach(async (to, from, next) => {
        // redirect to login page if not logged in and trying to access a restricted page
        store.dispatch('auth/updatePreviousPath', to.path);
        const { authorize } = to.meta;
        const currentUserRole = store.getters["auth/getUserRoles"];
        
        if(authorize){
            console.log(store.getters["auth/authenticated"]);
            console.log(currentUserRole);
            if(!store.getters["auth/authenticated"]){
                // not logged in so redirect to login page with the return url
                return next({ path: '/authorization/login', query: { returnUrl: to.path } });
            }
            // check if route is restricted by role
            if (authorize.length) {
    
                
                const contains = currentUserRole.some(element => {
                    return authorize.includes(element);
                });
                console.log(store.state.auth.user_roles);
                console.log(authorize);
                console.log(contains);
    
                if(!contains){
                    alert("NON AUTHORIZZATO")
                    // role not authorised so redirect to home page
                    return next({ path: '/pages/401' });
                }
            } 
        }
        next();
})

export default router;