import { createApp } from 'vue';
import { Bitrix24, useBitrix24 } from './plugins/vue-bitrix24';
import App from './App.vue'
import router from './router'
import CoreuiVue from '@coreui/vue-pro'
import { VueCookies } from 'vue3-cookies'
import { VueCookieNext } from 'vue-cookie-next';
import store from './store'
import vSelect from "vue-select";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import 'vue-select/dist/vue-select.css';
import './assets/styles/main.css';
import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const app = createApp(App);
app
.use(store)
.use(router)
.use(VueCookies)
.use(VueCookieNext)
.use(useBitrix24)
.component("v-select", vSelect)
;

CoreuiVue.install(app,null);

app.mount('#app');

