<template>
    <div>
        <div class="center-page d-flex flex-column align-items-center justify-content-center">
            <div class="row">
                <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
            <div class="row mt-4">
                <strong>Analizzando la richiesta...</strong>
            </div>
        </div>

    </div>
</template>

<script>


import setAuthHeader from '../api/setAuthHeader';
import SubscriptionService from '@/services/axios/SubscriptionService'

export default {
    name: 'RequestValidatorComponent',

    data(){
        return {
            subscriptionService: new SubscriptionService(),
            params: {}
        }
    },

    async created() {
        console.log('++++++++ TOKEN ++++++++++');
        console.log(this.$route.params.jwtToken);
        console.log(this.$route.params.placement);
        console.log('PLACEMENT_NAME');
        
        this.params =  
        {
            placement: this.$route.params.placement, 
            // ID DEL PLACEMENT
            id: this.$route.params.id,
            idPlacement: this.$route.params.idPlacement
        }

        setAuthHeader(this.$route.params.jwtToken);
        //await this.$store.dispatch('validateRequest');
        this.routeHandler()
        
    },

    methods: {

        async CheckIfSubscriptionsMoreThanOne(){
            var routerData = {};
            await this.subscriptionService.getSubsForUser()
                    .then(res=>{
                        var subs = res.data;
                        if (subs.length == 1) {
                            var sub = subs[0];

                            routerData = {
                                name: "sonet-ticket-list", 
                                params:{
                                    subId: sub.ID,
                                }
                            }
                            
                        }else {
                            routerData = {
                                name: 'sonet-subs',
                                params: this.params
                            }
                        }
                        
                    })
                    .catch(err=>{
                        console.log(err);
                        routerData = {
                            name: 'sonet-subs',
                            params: this.params
                        }
                        
                    })

                    return routerData;
        },
        
        async routeHandler(){
            var routeData = {
                name: "",
                params: {}
            };

            switch (this.$route.params.placement) {
                case "DEFAULT":
                    routeData = {
                        name: 'userHome'
                    }
                    break;
                
                case "SONET_GROUP_DETAIL_TAB":
                    routeData = await this.CheckIfSubscriptionsMoreThanOne();
                    break;    
                    
                default:
                    routeData = {
                        name: this.$route.params.placement,
                        params: this.params
                    }
                    
                    break;
            }
            this.$router.push(routeData);
            console.log("ROUTE_DATA");
            console.log(routeData);
            
        }
    },
}
</script>

<style>
    .center-page {
        position: fixed;
        z-index: 1031; /* High z-index so it is on top of the page */
        top: calc( 50% - ( 3rem / 2) ); /* where ... is the element's height */
        right: calc( 50% - ( 3rem / 2) ); /* where ... is the element's width */
    }

    .spinner-grow {
        animation: 1s linear infinite spinner-grow !important;
    }
</style>