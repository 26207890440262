<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script setup>

</script>

<style lang="css">

#app {
    padding-top: 0;
}
    
</style>
