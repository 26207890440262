import { createStore } from 'vuex'
import AuthModule from "./modules/auth.module"
import VuexPersist from 'vuex-persist';

const vuexLocalStorage = new VuexPersist({
    key: 'vuex',
    storage: window.sessionStorage
    // Function that passes the state and returns the state with only the objects you want to store.
    // reducer: state => state,
    // Function that passes a mutation and lets you decide if it should update the state in localStorage.
    // filter: mutation => (true)
})

export default createStore({
    namespaced: true,
    modules: {
        auth: AuthModule,
    },
    plugins: [vuexLocalStorage.plugin],
});