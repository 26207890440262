
import UseAuthenticationService from "../composition/AuthenticationService";
export default class SubscriptionService {

    constructor() {
        this.axios = UseAuthenticationService().CreateAxiosInstance();
    }

    async getSubsForUser() {
        return await this.axios.get('/api/SONET_GROUP_DETAIL_TAB/');
    }

    async getCompanyNameForSub(companyId) {
        return await this.axios.get('/api/subscription/company/' + companyId + '/name' );
    }

    async getCompanyIdBySubId(subId) {
        return await this.axios.get('/api/subscription/' + subId + '/company' );
    }
  
  }