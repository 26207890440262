<template>
    <div>
        <div class="center-page d-flex flex-column align-items-center justify-content-center">
            <div class="row">
                <div class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
            <div class="row mt-4">
                <strong>Analizzando la richiesta...</strong>
            </div>
        </div>

    </div>
</template>

<script>

/* eslint-disable */
import { useCookie } from "vue-cookie-next";
import setAuthHeader from '../api/setAuthHeader';
import SubscriptionService from '@/services/axios/SubscriptionService'
import UseAuthenticationService from '@/services/composition/AuthenticationService';
export default {
    name: 'RequestExtranetValidator',

    data(){
        return {
            subscriptionService: new SubscriptionService(),
            authService: UseAuthenticationService(),
            
        }
    },
    async created() {
        const { getCookie, isCookieAvailable } = useCookie()
        console.log(String(getCookie("token")))
        setAuthHeader(this.$route.params.jwtToken);
        this.routeHandler();

    },

    async mounted(){
        this.authService
    },

    methods: {

        async CheckIfSubscriptionsMoreThanOne(){
            var routerData = {};
            await this.subscriptionService.getSubsForUser()
                .then(res=>{
                    var subs = res.data;
                    if (subs.length == 1) {
                        var sub = subs[0];

                        routerData = {
                            name: "sonet-ticket-list", 
                            params:{
                                subId: sub.ID,
                            }
                        }
                        
                    }else {
                        routerData = {
                            name: 'sonet-subs',
                        }
                    }
                    
                })
                .catch(err=>{
                    console.log(err);
                    routerData = {
                        name: 'sonet-subs'
                    }
                    
                })

                return routerData;
        },
        
        async routeHandler(){
            var routeData = {
                name: "",
                params: {}
            };

            switch (this.$route.params.placement) {
                case "SONET_GROUP_DETAIL_TAB":
                    routeData = await this.CheckIfSubscriptionsMoreThanOne();
                    break;    
                    
                default:
                    routeData = {
                        name: this.$route.params.placement,
                        params: this.params
                    }
                    
                    break;
            }
            this.$router.push(routeData);
            
        }
    },
}
</script>

<style>
    .center-page {
        position: fixed;
        z-index: 1031; /* High z-index so it is on top of the page */
        top: calc( 50% - ( 3rem / 2) ); /* where ... is the element's height */
        right: calc( 50% - ( 3rem / 2) ); /* where ... is the element's width */
    }

    .spinner-grow {
        animation: 1s linear infinite spinner-grow !important;
    }
</style>