
import SubscriptionService from '@/services/axios/SubscriptionService'
import store from "@/store";
import axios from "axios";
import router from "@/router";
// Per richiedere il risultato dalla chache del browser dopo il primo invio reale
//import { cacheAdapterEnhancer } from 'axios-extensions';
export default function UseAuthenticationService(){

    const CreateAxiosInstance = () => {
        let axiosauthinstance;
        const WebServicesURL = process.env.VUE_APP_BE_URL;
        
        if(store.getters['auth/jwt'])
        {
            var token = store.getters['auth/jwt'];
            axiosauthinstance = axios.create({
                baseURL: WebServicesURL,
                timeout: 60000,
                headers: { 'Authorization': 'Bearer ' + token, 'Access-Control-Max-Age': 600, 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', /* 'SelectedLanguage': store.getAppLanguage */},
                //adapter: cacheAdapterEnhancer(axios.defaults.adapter)
            });

            axiosauthinstance.interceptors.request.use((config) => {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${token}`,
                };
                return config;
            });
        }
        else{
            axiosauthinstance = axios.create({
              baseURL: WebServicesURL, 
              timeout: 60000,
              headers: { 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', /* 'SelectedLanguage': store.getAppLanguage */},
              //adapter: cacheAdapterEnhancer(axios.defaults.adapter)
            });
        }
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        
        axiosauthinstance.interceptors.response.use((response) => response, (error) => {
            const status = error.response ? error.response.status : null;
            const originalRequest = error.config;
            if(status === 401){
                originalRequest._retry = true;
                store.dispatch("auth/logOut");
            }
            if(status === 400){
                originalRequest._retry = false;
            }      
            else if (error.message.includes("timeout of ") && !originalRequest._retry)
            {
                alert("Timeout waiting for the WebAPIs Engine");
            }
            return Promise.reject(error);
        });
        
        

        return axiosauthinstance;
    }

    const routeByPlacementAndUserRole = async (placement = null, idPlacement = null) => {
        if(!store.getters["auth/authenticated"]){
            alert("User Not Authenticated")
            router.push({name: "login"})
        }
        if(placement.length > 0){
            await routeHandler(placement, idPlacement)
        }
    }

    const routeHandler =  async (placement, idPlacement = null) => {
        var routeData = {
            name: "",
            params: {}
        };

        switch (placement) {
            case "EXTRANET" || "SONET_GROUP_DETAIL_TAB":
                routeData = await CheckIfSubscriptionsMoreThanOne();
                break;    
            case "DEFAULT":
                routeData.name = "userHome";
                break;
            case "CRM_DEAL_DETAIL_TAB":
                routeData.name = "CRM_DEAL_DETAIL_TAB";
                routeData.params = {
                    placement: placement,
                    idPlacement: idPlacement
                };
                break;
            case "CRM_COMPANY_DETAIL_TAB":
                routeData.name = "CRM_COMPANY_DETAIL_TAB",
                routeData.params = {
                    placement: placement,
                    idPlacement: idPlacement
                };
                break;
            default:
                routeData = {
                    name: placement,
                }
                break;
        }
        router.push(routeData);
        
    }

    const CheckIfSubscriptionsMoreThanOne = async () => {
        var routerData = {};
        const  subscriptionService = new SubscriptionService();
        await subscriptionService.getSubsForUser()
            .then(res=>{
                var subs = res.data;
                if (subs.length > 0) {
                    var sub = subs[0];

                    routerData = {
                        name: "sonet-ticket-list", 
                        params:{
                            subId: sub.ID,
                        }
                    }
                    
                }else {
                    routerData = {
                        name: 'sonet-subs',
                    }
                }
              console.log(res);  
            })
            .catch(err=>{
                console.log(err);
                routerData = {
                    name: 'sonet-subs'
                }
                
            })

        return routerData;
    }

    return { CreateAxiosInstance, routeByPlacementAndUserRole }
}