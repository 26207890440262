import { defineAsyncComponent, resolveComponent, h } from "vue";

import RequestValidator from "@/components/RequestValidator";
import RequestExtranetValidator from "@/components/RequestExtranetValidator";
import { Role } from "@/_helpers/role";
const UserHomeComponent = defineAsyncComponent(()=>
    import("@/components/UserHomeComponent")
);
/*
const SettingsPage = defineAsyncComponent(()=>
    import('@/views/SettingsPage')
);
const CategoryTableComponent = defineAsyncComponent(()=>
    import('@/views/categories/CategoryTableComponent')
);
const CreateCategory = defineAsyncComponent(()=>
    import("@/views/categories/CreateCategory")
);
const SubscriptionplanTableComponent = defineAsyncComponent(()=>
    import('@/views/subscriptionPlan/SubscriptionplanTableComponent')
);
const CreateSeverityLevel = defineAsyncComponent(()=>
    import('@/views/severityLevel/CreateSeverityLevel')
);

const SeverityLevelComponent = defineAsyncComponent(()=>
    import('@/views/severityLevel/SeverityLevelComponent')
);
*/
import WorkgroupTableComponent from "@/views/workgroups/WorkgroupTableComponent";

const loginRoutes = [
    {
        path: "/authorization/login",
        name: "login",
        component: () => import(/* webpackChunkName: "LoginPage" */ '@/views/Authorization/LoginView.vue')
    },
    {
        path: "/authorization/forgot/password",
        name: "forgot_password",
        component: () => import(/* webpackChunkName: "ForgotPAssword" */ '@/views/Authorization/ForgotPassword.vue')
    },
    {
        path: "/authorization/reset/password",
        name: "reset_password",
        component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/Authorization/ResetPassword.vue')
    },
    {
        path: "/authorization/email/confirm",
        name: "email_confirm",
        component: () => import(/* webpackChunkName: "EmailConfirm" */ '@/views/Authorization/EmailConfirm.vue')
    },
    {
        path: "/authorization/password/change",
        name: "change_password",
        component: () => import(/* webpackChunkName: "change_password" */ '@/views/Authorization/PasswordChange.vue')
    },

]

const userManagerRoutes = [
    
    {
        path: "add",
        name: "user_create",
        component: () => import(/* webpackChunkName: "user_add" */ '@/views/users/UserEdit.vue')
    },
    {
        path: "edit/:id",
        name: "user_edit",
        component: () => import(/* webpackChunkName: "user_add" */ '@/views/users/UserEdit.vue')
    },
];

const servicePages = [
    {
        path: '401',
        name: 'Page401',
        component: () =>
            import(
            '@/views/pages/Page401.vue'
            ),
    },    
    {
        path: '/:pathMatch(.*)*',
        name: 'Page404',
        component: () =>
            import(
            '@/views/pages/Page404.vue'
            ),
    },
]

const routes = [

    {
        path: "/",
        name: "base",
        redirect: "/authorization/login",
        component: () => import('@/layouts/GreyLayout.vue'),
        children: [
            {
                path: '/test',
                name: "test",
                component: () => import( /* webpackChunkName: "ButtonLoader" */ '@/views/TestPageComp.vue' )
            },
            {
                path: "/authorization",
                name: "auth",
                redirect: "/authorization/login",
                component: () => import("@/layouts/GreyLayout.vue"),
                children: loginRoutes
            },
            {
                path: "/pages",
                redirect: '/pages/404',
                name: "Pages",
                component: {
                  render() {
                    return h(resolveComponent('router-view'))
                  },
                },
                children: servicePages
            },
            {
                path: '/requestValidator/:jwtToken/:placement/:idPlacement',
                name: 'requestValidator',
                component: RequestValidator,
            },
            {
                path: '/extranetValidator/user/:jwtToken',
                name: 'requestExtranetValidator',
                component: RequestExtranetValidator,
            },
            {
                path: '/user/validation',
                name: 'userValidation',
                component: () => import("@/components/UserValidation.vue"),
            },
            {
                path: '/home',
                name: 'userHome',
                component: UserHomeComponent,
                meta: { authorize: [Role.Admin]},
                redirect: { name: 'category' },
                children: [
                    {
                        path: 'settings',
                        name: 'settings',
                        component: () =>
                            import(
                                /* webpackChunkName: "SettingsPage" */ 
                                '@/views/SettingsPage'
                            ),
        
                        children: [
                            {
                                path: 'users',
                                name: 'users',
                                component: () => import(/* webpackChunkName: "UsersIndex" */ '@/views/users/UsersIndex.vue' ),
                                children: userManagerRoutes
                            },
                            {
                                path: 'category',
                                name: 'category',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "CategoryTable" */ 
                                        '@/views/categories/CategoryIndex'
                                    )
                            },
                            {
                                path: 'category/add',
                                name: 'category-add',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "createCategory" */ 
                                        "@/views/categories/CreateCategory"
                                    )
                            },
                            {
                                path: '/category/edit/:id',
                                name: 'category-edit',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "editCategory" */ 
                                        "@/views/categories/CreateCategory"
                                    )
                            },
                            
                            {
                                path: 'workingtime',
                                name: 'workingtime',
                                component: () =>
                                    import(
                                        /* webpackChunkName: "workingTime" */ 
                                        "@/views/workingTime/CreateWorkingTime"
                                    )
                            },
                            {
                                path: 'workgroup',
                                name: 'workgroup',
                                component: WorkgroupTableComponent,
                                children: [
                                    {
                                        path: 'workgroup/add',
                                        name: 'workgroup-add',
                                    },
        
                                ]
                            },
                            {
                                path: 'sub-plan',
                                name: 'sub-plan',
                                component:  () =>
                                    import(
                                        /* webpackChunkName: "SubcriptionPlan" */
                                        '@/views/subscriptionPlan/SubscriptionPlanIndex'
                                    )
                            },
                            {
                                path: 'sub-plan/add',
                                name: 'sub-plan-add',
                                component:  () =>
                                    import(
                                        /* webpackChunkName: "SubcriptionPlanCreate" */
                                        '@/views/subscriptionPlan/CreateSubscriptionPlan'
                                    )
                               
                            },
                            {
                                path: 'sub-plan/:id',
                                name: 'sub-plan-edit',
                                component:  () =>
                                    import(
                                        /* webpackChunkName: "SubcriptionPlanEdit" */
                                        '@/views/subscriptionPlan/CreateSubscriptionPlan'
                                    )
                            },
        
                            {
                                path: 'severity-level',
                                name: 'severity-level',
                                component:  () =>
                                    import(
                                        /* webpackChunkName: "SeverityLevel" */
                                        '@/views/severityLevel/SeverityLevelIndex'
                                    )
                            },
                            {
                                path: 'severity-level/add',
                                name: 'severity-level-add',
                                component:  () =>
                                    import(
                                        /* webpackChunkName: "SeverityLevelAdd" */
                                        '@/views/severityLevel/CreateSeverityLevel'
                                    )
                            },
                            {
                                path: 'severity-level/:id',
                                name: 'severity-level-edit',
                                component:  () =>
                                import(
                                    /* webpackChunkName: "SeverityLevelEdit" */
                                    '@/views/severityLevel/CreateSeverityLevel'
                                )
                            },
        
                            {
                                path: 'prioritylevelsubscriptionplan',
                                name: 'priority-level-subscription-plan',
                                component:  () =>
                                import(
                                    /* webpackChunkName: "SeverityLevelEdit" */
                                    '@/views/SLA/SlaTableComponent'
                                )
                            },
        
                            {
                                path: 'subcription/deal/:id',
                                name: 'deal-subscription-home',
                                component:  () =>
                                import(
                                    /* webpackChunkName: "DealTabId" */
                                    '@/views/deal_tab/HomeDealTab'
                                )
                            },
                        ]
                    },
                    
                ],
            },
        
            {
                path: '/crm/placement/deal/:placement/:idPlacement',
                name: 'CRM_DEAL_DETAIL_TAB',
                meta: { authorize: [Role.Admin]},
                component:  () =>
                import(
                    /* webpackChunkName: "Deal_placement_home" */
                    '@/views/placementCRMDeal/CRMDealHome'
                ),
                children: [
                    {
                        path: 'company/:idCompany/newsub',
                        name: 'add-sub-for-company',
                        component: () =>
                            import(
                                /* webpackChunkName: "create_subscription" */ 
                                '@/views/placementCRMDeal/CreateSubscription'
                            )
                    },
        
                    {
                        path:'subs/list/:idPlacement',
                        name: "subs-list-component",
                        component:  () =>
                        import(
                            '@/views/placementCRMCompany/SubscriptionsList'
                        ),
                    },
        
                ] 
            },
            {
                path: '/crm/placement/company/:placement/:idPlacement',
                name: 'CRM_COMPANY_DETAIL_TAB',
                meta: { authorize: [Role.Admin]},
                redirect: { name: 'company-subs-list' },
                component:  () =>
                import(
                    '@/views/placementCRMCompany/CRMCompanyHome'
                ),
                children: [
                    {
                        path: 'company/:idPlacement/subscriptions',
                        name: 'company-subs-list',
                        component: () =>
                            import(
                                /* webpackChunkName: "create_subscription" */ 
                                '@/views/placementCRMCompany/SubscriptionsList'
                            )
                    },
                    {
                        path: 'company/:idPlacement/subscription/create',
                        name: 'company-sub-create',
                        component: () =>
                            import(
                                /* webpackChunkName: "create_subscription" */ 
                                '@/views/placementCRMDeal/CreateSubscription'
                            )
                    },
                    
                    {
                        path: 'subscription/edit/:subId',
                        name: 'company-sub-edit',
                        component: () =>
                            import(
                                /* webpackChunkName: "CreateSubscription" */ 
                                '@/views/placementCRMCompany/EditSubscription'
                            )
                    },
                    {
                        path: 'company/:idPlacement/extranetusers',
                        name: 'company-extranet-users-list',
                        component: () =>
                            import(
                                /* webpackChunkName: "create_subscription" */ 
                                '@/views/placementCRMCompany/ExtranetUsersList'
                            )
                    },
                    {
                        path: 'company/extranetusers/add',
                        name: 'company-extranet-users-add',
                        component: () =>
                            import(
                                /* webpackChunkName: "create_subscription" */ 
                                '@/views/placementCRMCompany/ExtranetUsersAdd'
                            )
                    },
                ] 
            },
            {
                path: '/extranet/user',
                name: 'SONET_GROUP_DETAIL_TAB',
                component: () =>
                    import(
                        /* webpackChunkName: "extranetUserHome" */
                        '@/views/sonetGroup/SonetHome'
                    ),
                redirect: {name: 'sonet-subs'},
                children:[
                    {
                        path: 'extranet/subscription/:subId/ticket/list',
                        name: 'sonet-ticket-list',
                        component: () => 
                            import(
                                '@/views/sonetGroup/subscription/SubscriptionPage'
                            )
                    },
                    
                    {
                        path: 'extranet/user/subs',
                        name: 'sonet-subs',
                        component: () =>
                            import(
                                '@/views/sonetGroup/SubscriptionIndex'
                            )
                    },
                    /*
                    {
                        path: 'extranet/:idWG/user/:id/subscription/:subId/b24Company',
                        name: 'sonet-subscription',
                        component: () =>
                            import(
                                '@/views/sonetGroup/subscription/SubscriptionPage'
                            )
                    },
        
                    */
                    {
                        //path: 'extranet/:idWG/user/:id/subscription/:subId/ticket',
                        path: 'extranet/user/subscription/:subId/ticket',
                        name: 'sonet-ticket-add',
                        component: () =>
                            import(
                                '@/views/sonetGroup/ticket/TicketCreate'
                            )
                    },
                    {
                        path: 'extranet/subscription/:subId/ticket/:ticketId',
                        name: 'sonet-ticket-show',
                        component: () =>
                            import(
                                '@/views/sonetGroup/ticket/TicketShow'
                            )
                    },
                ]
            }
        ]
    }
]

export default routes;