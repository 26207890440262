import axios from 'axios';
import { useCookies } from "vue3-cookies"
const { cookies } = useCookies();


const setAuthHeader = (token) => {
    if(token){
        
        console.log("SET_AUTH_HEADER");
        //console.log(token);
        console.log(cookies.get("FILTER_APP"));
        //console.log(this.$cookies.get('FILTER_APP'));
        axios.defaults.headers = {
            Authorization: "Bearer " + token,
            "Content-Type": "Application/json",
        }
    }else{
        delete axios.defaults.headers.Authorization;
    }
};

export default setAuthHeader;